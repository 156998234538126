<template>
  <v-card
    max-height="calc(100vh - 48px - 32px)"
    class="rounded-lg"
    >
    <v-card-text
      class="black--text"
      >
      <div
        class="text-center"
        >
        <v-icon
          x-large
          color="primary"
          >
          mdi-cog
        </v-icon>
      </div>

      <div
        class="text-h6 font-weight-bold text-center mb-1 primary--text"
        >
        Datos de la feria
      </div>

      <div
        class="body-1 mb-3 text-center black--text"
        >
        <v-snackbar
          v-model="copied"
          color="success darken-1"
          >
          <v-icon>mdi-content-copy</v-icon>

          Enlace copiado con éxito.
        </v-snackbar>

        <span @click="copyInvite" style="cursor: pointer" class="info--text font-weight-medium">Haz click aquí</span> para copiar el enlace público de la feria, o <span @click="downloadQr" style="cursor: pointer" class="info--text font-weight-medium">aquí para descargar un código QR <v-icon class="mt-n1" color="info">mdi-qrcode-scan</v-icon></span>

        <div
          class="d-none"
          id="canvas"
          ></div>
        <canvas
          id="canvas_"
          class="d-none"
          ></canvas>
      </div>

      <v-form
        v-model="valid"
        ref="form"
        lazy-validation
        class="d-flex flex-column"
        style="gap: 4px"
        >
        <v-text-field
          v-model="attributes.name"
          outlined
          class="rounded-lg"
          :rules="required"
          label="Nombre de la feria"
          dense
          ></v-text-field>

        <v-text-field
          v-model="attributes.owner"
          outlined
          class="rounded-lg"
          :rules="required"
          label="Individuo o entidad a cargo"
          hint="¿Quién llevará adelante la feria?"
          dense
          ></v-text-field>

        <v-text-field
          v-model="attributes.commission"
          type="number"
          outlined
          class="rounded-lg"
          :rules="required"
          label="Comisión por venta"
          dense
          ></v-text-field>

        <div
          class="d-flex flex-column flex-md-row"
          :style="{ gap: $vuetify.breakpoint.mdAndUp ? '12px' : '4px' }"
          >
          <v-menu
            v-model="startsAtMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="attributes.startsAt"
                label="Fecha de inicio"
                outlined
                dense
                prepend-inner-icon="mdi-calendar"
                readonly
                :rules="required"
                v-bind="attrs"
                v-on="on"
                ></v-text-field>
            </template>

            <v-date-picker
              v-model="attributes.startsAt"
              @input="startsAtMenu = false"
              ></v-date-picker>
          </v-menu>

          <v-menu
            v-model="endsAtMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="attributes.endsAt"
                label="Fecha de fin"
                outlined
                dense
                prepend-inner-icon="mdi-calendar"
                readonly
                :rules="required"
                v-bind="attrs"
                v-on="on"
                ></v-text-field>
            </template>

            <v-date-picker
              v-model="attributes.endsAt"
              @input="endsAtMenu = false"
              ></v-date-picker>
          </v-menu>
        </div>

        <v-textarea
          v-model="attributes.description"
          outlined
          class="rounded-lg"
          :rules="required"
          label="Descripción"
          hint="Cuéntanos un poco más sobre tu feria y de qué se trata."
          dense
          ></v-textarea>

        <v-btn
          block
          color="primary"
          depressed
          block
          class="rounded-lg"
          @click="updateFair"
          >
          Confirmar
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Filesystem, Directory } from '@capacitor/filesystem'
import { Share } from '@capacitor/share'

import html2canvas from 'html2canvas'
import { jsPDF } from "jspdf"
import QRCodeStyling from 'qr-code-styling'

import { mapGetters } from 'vuex'

import { EditFair } from '@/graphql/mutations/fairs/fairs'

export default {
  data: () => ({
    valid: true,
    copied: false,
    startsAtMenu: false,
    endsAtMenu: false,
    required: [
      v => !!v || 'Campo obligatorio'
    ],
    attributes: {
      name: null,
      owner: null,
      startsAt: null,
      endsAt: null,
      description: null,
      commission: null,
    }
  }),

  props: {
    fair: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['baseUrl', 'isNative', 'url'])
  },

  created () {
    Object.keys(this.attributes).forEach( k => {
      this.attributes[k] = this.fair[k]
    })
  },

  methods: {
    updateFair () {
      if (this.$refs.form.validate()) {
        this.$apollo.mutate({
          mutation: EditFair,
          variables: {
            input: {
              attributes: this.attributes,
              id: this.fair.id
            }
          }
        }).then ( res => {
          this.$emit('reload')
        })
      }
    },

    copyInvite () {
      navigator.clipboard.writeText(`${this.baseUrl}feria/${this.fair.slug}`)

      this.copied = true
    },

    async downloadQr () {
      const qrCode = new QRCodeStyling({
        width: 1200,
        height: 1200,
        type: "png",
        data: `${this.baseUrl}feria/${this.fair.slug}`,
        image: this.url + this.fair.image.url,
        dotsOptions: {
          type: "rounded"
        },
        backgroundOptions: {
          color: "#e9ebee",
        },
        imageOptions: {
          crossOrigin: "anonymous",
          margin: 20
        }
      });

      if (this.isNative) {
        try {
          var div = document.getElementById('canvas')
          qrCode.append(div);

          setTimeout( async () => {
            var svg = div.getElementsByTagName('svg')[0]
            var xml = new XMLSerializer().serializeToString(svg)
            var svg64 = btoa(xml)
            var b64start = 'data:image/svg+xml;base64,'
            var image64 = b64start + svg64

            var canvas = document.getElementById('canvas_');
            // get canvas context for drawing on canvas
            var context = canvas.getContext('2d');
            canvas.width = 1200;
            canvas.height = 1200;

            var image = new Image();

            image.onload = function () { // async (happens later)
              // clear canvas
              context.clearRect(0, 0, 1200, 1200);
              // draw image with SVG data to canvas
              context.drawImage(image, 0, 0, 1200, 1200);
              // snapshot canvas as png
              var pngData = canvas.toDataURL('image/png');
              // pass png data URL to callback
              //callback(pngData);
              pngData
            }; // end async
            // start loading SVG data into in memory image

            image.src = image64;

            setTimeout( async () => {
              var base64Data = canvas.toDataURL()

              const savedFile = await Filesystem.writeFile({
                path: 'Mi QR.png',
                data: base64Data,
                directory: Directory.Cache
              }).then( res => {
                return Filesystem.getUri({
                  directory: Directory.Cache,
                  path: 'Mi QR.png'
                });
              }).then( uriResult => {
                return Share.share({
                  title: 'Mi QR.png',
                  text: '',
                  url: uriResult.uri,
                });
              })

              this.$emit('success')
            }, 500)
          }, 500)
        } catch (error) {
          alert(error)
        }
      } else {
        qrCode.download({ name: this.fair.name, extension: "png" });
      }
    },
  }
}
</script>
